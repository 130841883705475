<!--<ng-template *ngTemplateOutlet="tableRef; context: {$implicit: field}"></ng-template>-->
<ng-template #emailField>
  <label *ngIf="!parent.isDesktopDevice">Email</label>
  <div class="field fluid mb-3">
    <input id="username" name="username" type="email" pInputText placeholder="Email" [ngClass]="{'invalid': isFieldInvalid()}"
           [(ngModel)]="loginInfo.username"  [required]="true" aria-errormessage="username-error" (blur)="hasError = !isValidEmail; hasUserError=false;" (keydown.enter)="signIn()"/>
    <small class="login-error mb-1" id="username-error" *ngIf="hasError">Please enter a valid email address.</small>
    <small class="login-error mb-1" id="user-not-found-error" *ngIf="hasUserError">Email address is not recognized.</small>
    <ng-container *ngIf="!hasError">
      <small class="login-error" *ngIf="isShowLicenseError">User is no longer active. Please contact amc&#64;adcore.com</small>
      <small class="login-error" *ngIf="!isShowLicenseError && isUserEmailNotFound && failedAttempts > 0 && failedAttempts < (maxAttempts - 1)">Email does not exist.</small>
      <small class="login-error" *ngIf="!isShowLicenseError && isAdvertiserNotActive && failedAttempts > 0 && failedAttempts < (maxAttempts - 1)">The user advertiser is not active.</small>
      <small class="login-error" *ngIf="!isShowLicenseError && !isUserEmailNotFound && !isAdvertiserNotActive && failedAttempts > 0 && failedAttempts < (maxAttempts - 1)">Wrong login details. Please try again.</small>
      <small class="login-error" *ngIf="!isShowLicenseError && failedAttempts === (maxAttempts - 1)">This is the last attempt. Please try again or click "Forgot password"</small>
      <small class="login-error" *ngIf="!isShowLicenseError && isLoginBlocked">Login is Blocked. Please contact amc&#64;adcore.com</small>
    </ng-container>
  </div>
</ng-template>

<div class="ml-8 login-container" [ngClass]="parent.isDesktopDevice ? '': 'login-mobile'" *ngIf="!isForgotPassword">
  <ng-container *ngIf="!show2fa">
    <div class="text-center"><h1 class="mb-3">sign in</h1></div>
    <div class="text-center mb-3"><div class="new-user">New user?</div><p-button styleClass="p-button-link" label="Create an account" [link]="true" (onClick)="createAccount()"></p-button></div>
    <div class="text-center connect" *ngIf="!parent.isDesktopDevice">Connect With</div>
    <div class="justify-content-center mb-4" [ngClass]="!parent.isDesktopDevice ? 'block': 'flex'">

      <div class="align-g-btn">
        <asl-google-signin-button type='standard' size='large' [width]="214" logo_alignment="left" text="signin_with"  shape="rectangular" theme="filled_blue"></asl-google-signin-button>
      </div>
      <div>
        <p-button label="Sign in with Facebook" icon="pi pi-facebook" styleClass="social-button social-button-facebook" (onClick)="parent.signInWithFB()"></p-button>
      </div>
      <!--    <p-button label="Sign in with Google" icon="pi pi-google" styleClass="social-button social-button-google" (click)="parent.signInWithGoogle()"></p-button>
          <asl-google-signin-button *ngIf="parent.isDesktopDevice" type='standard' size='large' logo_alignment="left" text="signin_with"  shape="rectangular" theme="filled_blue"></asl-google-signin-button>
          <asl-google-signin-button *ngIf="!parent.isDesktopDevice" type='icon' size='large' logo_alignment="center"  shape="square" theme="outline" id="customGoogleBtn"></asl-google-signin-button>
       <p-button *ngIf="!parent.isDesktopDevice" icon="pi pi-google" styleClass="social-button social-button-google"></p-button>
        -->
    </div>
    <p-divider align="center" [ngClass]="!parent.isDesktopDevice ? 'divider-mobile': ''"><span class="text-uppercase">or</span></p-divider>
    <ng-template *ngTemplateOutlet="emailField;"></ng-template>
    <label *ngIf="!parent.isDesktopDevice">Password</label>
    <div class="field fluid mb-3">
    <span class="p-input-icon-right">
      <i class="pi pi-eye pointer eye" *ngIf="p.type === 'password'" (click)="p.type = 'text'"></i>
      <i class="pi pi-eye-slash pointer eye" *ngIf="p.type === 'text'" (click)="p.type = 'password'"></i>
      <input #p id="password" name="password" type="password" pInputText placeholder="Password"
             [(ngModel)]="loginInfo.password"  [required]="true" (keydown.enter)="signIn()">
    </span>
      <p-button type="button" styleClass="p-button-link forgot" label="Forgot?" (onClick)="isForgotPassword = true" [link]="true"></p-button>
    </div>
    <div class="field fluid mb-3 text-center">
      <p-button label="sign in" styleClass="login-btn-secondary" (onClick)="signIn()"></p-button>
    </div>
  </ng-container>
  <ng-container *ngIf="show2fa">
    <div class="text-center"><h1 class="mb-3">Verify Your Identity</h1></div>
    <div class="text-center mb-3">
      <div>We’ve sent a one-time passcode to your mobile number. </div>
      <div>Enter the code below to sign in.</div>
    </div>
    <div class="field fluid mb-3" [ngClass]="{'invalid-border': codeError, 'green-border': phoneCodeCorrect}">
      <aux-digit-code #digitCode [amount]=6 (applyingCode)="onApplyingPhoneCode($event)"></aux-digit-code>
      <small class="login-error mb-1" *ngIf="codeError">Invalid verification code</small>
      <div class="upgrade-login-verify-links  text-center">
        <p-button label="Resend code" styleClass="p-button-link" (onClick)="send2faRequest()" [disabled]="inProcess || resendTimes >= 6 || isResendDisabled"></p-button>
        <div *ngIf="isResendDisabled">({{countdown}} seconds)</div>
      </div>
    </div>
  </ng-container>

  <div class="text-center login-explain">
    <small class="text-wrap">By joining you agree to Adcore's <a href="https://www.adcore.com/terms-of-use/"
                                                                   target="_blank">Terms of Service</a> as well as
      to receive occasional emails from us</small>
  </div>
</div>

<div class="ml-8 login-container forgot-password" [ngClass]="parent.isDesktopDevice ? '': 'login-mobile'" *ngIf="isForgotPassword">
  <div class="text-center"><h1 class="mb-3">Forgot your password?</h1></div>
  <div class="text-center login-explain">
    <small class="text-wrap">Don't worry! Resetting your password is easy. Just type in the email you registered to Adcore.</small>
  </div>
  <ng-template *ngTemplateOutlet="emailField;"></ng-template>
  <div class="field fluid mb-3 text-center">
    <p-button label="send email" styleClass="login-btn-secondary" (onClick)="sendEmail()"></p-button>
  </div>
  <div class="text-center mb-3"><div class="new-user">Did you remember your password?</div><p-button styleClass="p-button-link" label="Try logging in" (onClick)="isForgotPassword = false" [link]="true"></p-button></div>
</div>

<p-toast position="top-center" key="reset-link" ngClass="green-palette"></p-toast>

