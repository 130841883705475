
<div class="text-center login-top-bg" *ngIf="!isDesktopDevice && !routeUrl.includes('join')">
  <p-image src="assets/images/amc-login-top-bg.png" alt="Image"></p-image>
  <h3>ADCORE MARKETING CLOUD</h3>
</div>

<div class="grid login-page" [ngClass]="{'mobile-layout' : !isDesktopDevice, 'join': routeUrl.includes('join') }">
  <div class="login-page-box" id="loginPageBox" [ngClass]="{'full-h': isBottomSticky}">
    <div class="col img-section" *ngIf="isDesktopDevice && !routeUrl.includes('join')">
      <div class="login-left-side">
        <div class="login-left-side-container grid">
          <div class="flex flex-column">
            <div class="title">
              <div class="login-logo"></div>
              <!--<img src="assets/images/adcore.png"> -->
            </div>
            <div class="secondary-title" *ngIf="routeUrl.indexOf('mypass') === -1">MARKETING CLOUD</div>
            <div class="icons-box">
              <div>
                <span><img src="assets/icons/em-login.svg"></span>
                <span><img src="assets/icons/fe-login.svg"></span>
                <span><img src="assets/icons/vi-login.svg"></span>
              </div>
              <div>
                <span><img src="assets/icons/se-login.svg"></span>
                <span><img src="assets/icons/at-login.svg"></span>
                <span><img src="assets/icons/hu-login.svg"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p-card [styleClass]="planClass + ' forDesktop'" *ngIf="isDesktopDevice && routeUrl.includes('join')">
      <div><p-image [styleClass]="planLogoClass" [src]="planLogo" alt="plan-logo"></p-image></div>
      <div class="plan-title" [ngClass]="selectedStage"><div>{{planTitleNormal}}</div><div class="bold">{{planTitleStrong}}</div></div>
      <ng-container *ngIf="selectedStage==='stage1'">
        <ul class="plan-check-marks">
          <li *ngFor="let c of planCheckMarks"><i></i><span>{{c}}</span></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="selectedStage==='stage2'">
        <div class="rating inline-flex">
          <div class="plan-rating google"><i class="first-logo"></i><span>{{rating.google}}</span><i class="star"></i></div>
          <div class="plan-rating facebook"><i class="first-logo"></i><span>{{rating.facebook}}</span><i class="star"></i></div>
        </div>
        <div class="carusel-box">
          <p-carousel #csl styleClass="mb-3" [value]="reviews" [showNavigators]="false" [circular]="true" [numScroll]="1" [numVisible]="1">
            <ng-template let-review pTemplate="item">
              <div class="text-right">
                <i class="pi pi-facebook"></i>
              </div>
              <p (click)="csl.step(1)">
                {{review.body}}
              </p>
              <div class="inline-flex align-items-center carusel-bottom-content">
                <div class="avatar-box"><p-avatar icon="pi pi-user" size="normal" shape="circle"></p-avatar></div>
                <div class="text" *ngIf="review.author && review.company">
                  <span class="bold">{{review.author}} - </span>
                  <span>{{review.company}}</span>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
      </ng-container>
      <p class="text-center plan-text" *ngIf="selectedStage === 'stage3'" [innerHTML]="planText"></p>
      <div class="bottom-apps" [ngClass]="selectedStage">
        <div class="divider-box" *ngIf="selectedStage !== 'stage3'"><p-divider styleClass="mb-2"></p-divider></div>
        <p-image src="assets/images/join/apps-new.svg" alt="apps-logo"></p-image>
      </div>
    </p-card>
    <div [ngClass]="isDesktopDevice? 'form-section' : 'col-12'">
      <router-outlet></router-outlet>
    </div>
    <span style="color: transparent!important;font-size: 1px;">{{isBottomSticky}}</span>
    <div class="app-bottom-mobile" id="bottomMobile" *ngIf="!isDesktopDevice && routeUrl.includes('join')" [ngClass]="{'sticky': isBottomSticky}">
      <p-card [styleClass]="planClass + ' ' + selectedStage">
        <div>
          <div class="logo-img" [ngClass]="selectedStage" *ngIf="selectedStage !== 'stage1'"><p-image [styleClass]="planLogoClass" [src]="planLogo" alt="plan-logo"></p-image></div>
          <div class="plan-title" [ngClass]="selectedStage"><div *ngIf="planTitleNormal">{{planTitleNormal}}</div><div class="bold" *ngIf="planTitleStrong">{{planTitleStrong}}</div></div>
        </div>
        <div class="rating inline-flex" *ngIf="selectedStage==='stage2'">
          <div class="plan-rating google"><i class="first-logo"></i><span>{{rating.google}}</span><i class="star"></i></div>
          <div class="seperator"></div>
          <div class="plan-rating facebook"><i class="first-logo"></i><span>{{rating.facebook}}</span><i class="star"></i></div>
        </div>
        <div *ngIf="selectedStage === 'stage3'" >
          <p class="text-center plan-text" [innerHTML]="planText"></p>
          <div class="bottom-apps">
            <p-image src="assets/images/join/apps-new.svg" alt="apps-logo"></p-image>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>


