<!--{{readonly}}-->
<!--{{editPlaceholder}}-->
<!--{{activated}}-->
<p-inplace #pi styleClass="ml-2 mb-3" [preventClick]="true" *ngIf="!hide" [active]="activated"
           (onActivate)="onActivate($event)" (onDeactivate)="onDeactivate($event)">
  <ng-template pTemplate="display">
    <div class="inline-flex align-items-center">
      <div class="value-read-mode">
        <h4>{{translate(editLabel)}}</h4>
        <div *ngIf="!readonly">{{editObject[editProp] || translate(editPlaceholder)}}</div>
        <div *ngIf="readonly && editPlaceholder">{{translate(editPlaceholder)}}</div>
        <div *ngIf="readonly && !editPlaceholder">{{editObject[editProp]}}</div>
      </div>
      <i class="pi pi-pencil value-icon" (click)="open($event)" *ngIf="!readonly"></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="inline-flex align-items-center">
      <ng-container [ngSwitch]="inputType">
        <div class="value-other-mode" *ngIf="listOfValues.length">
          <h4>{{translate(editLabel)}}</h4>
<!--          {{listOfValues | json}}-->
          <p-dropdown #dd [options]="listOfValues" [(ngModel)]="localObject[editProp]"
                      (onChange)="changeProp($event)" *ngSwitchCase="'list'" [disabled]="disabled">
            <ng-template  pTemplate="selectedItem">
              {{translate(selectedOptionFromList.label)}}
            </ng-template>
            <ng-template let-option pTemplate="item">
              {{translate(option.label)}}
            </ng-template>
          </p-dropdown>
          <p-inputSwitch [(ngModel)]="localObject[editProp]" *ngSwitchCase="'switch'" (onChange)="changeProp($event)"
                         [trueValue]="listOfValues[0]" [falseValue]="listOfValues[1]" [disabled]="disabled"></p-inputSwitch>
        </div>
        <input type="text" [(ngModel)]="localObject[editProp]" (keyup.enter)="changeProp($event)"
               [placeholder]="translate(editPlaceholder) || translate(editLabel)"
               class="value-edit-mode" pInputText *ngSwitchDefault (keyup.escape)="onOutsideClick($event)" pAutoFocus [autofocus]="true">
      </ng-container>
<!--      (clickOutside)="onOutsideClick($event)"-->
      <i class="pi pi-times value-icon" (click)="close($event)" *ngIf="!activated"></i>
    </div>
  </ng-template>
</p-inplace>
