import {EventEmitter, Injectable} from '@angular/core';
import * as models from './models';
import * as cfg from './config';
import {ApiCallService} from './api-call.service';
import {MediaService} from './media.service';
import {Observable, Subject, EMPTY} from 'rxjs';
import {Router} from '@angular/router';
import * as aux from 'adcore-auxiliary';
import {SocialAuthService} from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationStatus: models.IAuthState = {};
  onSignInSuccess: EventEmitter<any> = new EventEmitter<any>();
  onAuthenticationEnd: EventEmitter<void> = new EventEmitter<void>();
  onAuthenticationStart: EventEmitter<void> = new EventEmitter<void>();

  constructor(private apiCallService: ApiCallService, private authService: SocialAuthService,
              private mediaService: MediaService, private router: Router, private windowRefService: aux.WindowRefService) {
  }

  get lastRoute(): string {
    return this.windowRefService.getLocalData(cfg.ELocalStorageTokens.LAST_ROUTE);
    // return this.cookieService.get(cfg.ECookieTokens.LAST_ROUTE) || '';
  }

  set lastRoute(value: string) {
    if (value) {
      this.windowRefService.setLocalData(cfg.ELocalStorageTokens.LAST_ROUTE, value.replace('/', ''));
      // this.cookieService.set(cfg.ECookieTokens.LAST_ROUTE, value.replace('/', ''), cfg.COOKIE_DAYS_EXPIRE, '/');
    }
  }

  signIn(loginInfo: models.ILoginInfo): void {
    this.apiCallService.authorization(loginInfo)
      .toPromise()
      .then((response) => {
        // console.log({response});
        response = response || {};
        const token = ((response.token) ? response.token.token : '') as string;
        const user = {id: response.userId, email: response.username, role: response.role, photo: response.photo} as models.IUser;
        user.upgradedLogin = response.upgradedLogin;
        user.mobile = response.mobile;
        this.mediaService.accessToken = token;
        this.mediaService.currentUser = user;
        this.authenticationStatus.err = response.err || null;
        this.authenticationStatus.errCode = response.errCode || null;
        this.authenticationStatus.failedAttempts = response.failedAttempts || null;
        this.authenticationStatus.result = Boolean(token && user.id);
        this.authenticationStatus.role = user.role;
        this.onSignInSuccess.emit({token, user});
      }, (reason) => {
        console.log({reason});
        this.authenticationStatus = {};
        this.mediaService.accessToken = null;
        this.mediaService.currentUser = null;
      });
  }

  validate(route: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.apiCallService.authenticationValidation()
      .toPromise()
      .then((response) => {
        this.authenticationStatus = response || {};
        // console.log({route});
        if (route === '/administration' && this.authenticationStatus?.role !== 'admin'
          && !cfg.SUPER_ADMIN_REGEX.test(this.authenticationStatus?.email)) {
          // subject.next(this.authenticationStatus.result);
          subject.next(false);
        }
        subject.next(this.authenticationStatus.result || false);
      }, (reason) => {
        console.log({reason});
        this.mediaService.accessToken = null;
        this.mediaService.currentUser = null;
        subject.next(this.authenticationStatus.result || false);
      });
    return subject.asObservable();
  }

  validateForgotPasswordToken(token: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.apiCallService.validateForgotPasswordToken(token)
      .toPromise()
      .then((response) => {
        const r = response || {};
        subject.next(r.result);
      }, (reason) => {
        console.log({reason});
        subject.next(false);
      });
    return subject.asObservable();
  }

  validateInvitationToken(token: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    this.apiCallService.validateInvitationToken(token)
      .toPromise()
      .then((response) => {
        const r = response || {};
        if (r.error) {
          subject.next(false);
          return;
        }
        subject.next(true);
      }, (reason) => {
        console.log({reason});
        subject.next(false);
      });
    return subject.asObservable();
  }
  private _authenticate(): void {
    this.onAuthenticationStart.emit();
    // console.log('authentication started');
    this.apiCallService.authentication()
      .toPromise()
      .then((response) => {
        if (response) {
          // console.log('authentication: ', {response});
          this.mediaService.currentUser = response.user || null;
          this.mediaService.userAdvertiser = response.advertiser || null;
          if (this.mediaService.currentUser) {
            this.authenticationStatus = {result: true, role: this.mediaService.currentUser.role};
          }
        }
        this.onAuthenticationEnd.emit();
      }, (reason) => {
        this.mediaService.currentUser = null;
        this.onAuthenticationEnd.emit();
      });
  }
  authenticate(): void {
    this.mediaService.isAuthenticationInProcess = true;
    const instance = this.windowRefService.queryParams?.get('instance');
    const path = decodeURIComponent(this.windowRefService.href || '');
    // const url = decodeURIComponent(this.router.url || '');
    // might be no need to save advertiser
    if (path.includes('/effortless') && instance) {
      this.mediaService.accessToken = null;
      try {
        this.mediaService.effortlessInstance = JSON.parse(atob(instance));
        this.mediaService.accessToken = this.mediaService.effortlessInstance?.token;
      } catch (e: any) {
        console.error('authenticate:', {e});
      }
      // console.log('authenticate:', {instance, path,
      // ei: this.mediaService.effortlessInstance, token: this.mediaService.accessToken});
      setTimeout(() => {
        this._authenticate();
      }, 200);
    }else {
      this._authenticate();
    }
  }

  signOut(): void {
    this.windowRefService.cleanSessionData();
    this.mediaService.currentUser = null;
    this.mediaService.accessToken = null;
    this.authService.signOut().then((r) => {
    }, (e) => {
    });
    this.windowRefService.reloadLocation();
    // this.router.navigateByUrl('/login');
  }

  forgotPassword(username: string | null): Observable<any> {
    const subject = new Subject<any>();
    if (username) {
      this.apiCallService.forgotPasswordFlow(username).toPromise().then((response) => {
        const r = response || {};
        subject.next(r);
      }, (reason) => {
        console.log({reason});
        subject.next({});
      });
    }
    return subject.asObservable();
  }

  isBlocked(username: string): void {
    this.apiCallService.isBlockedCheck(username)
      .toPromise()
      .then((response) => {
        response = response || {};
        this.authenticationStatus.failedAttempts = response.failedAttempts || 0;
        // this.authenticationStatus.result = response.result || false;
      }, (reason) => {
        this.authenticationStatus.failedAttempts = 0;
        // this.authenticationStatus.result = false;
      });
  }
}
