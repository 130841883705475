<div class="kyc" [attr.isDesktop]="isDesktopDevice">
  <div class="left-panel" *ngIf="isDesktopDevice">
    <div class="top">
      <a href="https://www.adcore.com/technologies/media-blast" style="display: block">
        <span class="logo"></span>
        <label style="cursor: pointer">{{translate('MEDIA_BLAST')}}</label>
      </a>
    </div>
    <div class="middle">
      <label class="title">{{translate("MB_KYC_LEFT_TITLE")}}</label>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_ONE")}}</div>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_TWO")}}</div>
      <div><span><i></i></span>{{translate("MB_KYC_LEFT_THREE")}}</div>
    </div>
    <div class="bottom">
      <!--<div class="ad-logo"></div> -->
      <div class="text">Accounts we provide</div>
      <div class="logos">
        <a class="logo-1" href="https://www.google.com/partners/agency?id=3987411150" target="_blank"><i></i></a>
        <a class="logo-2" href="https://www.facebook.com/business/partner-directory/search?id=1731416420349160" target="_blank"><i></i></a>
        <a class="logo-3" href="https://about.ads.microsoft.com/en-us/resources/partner/did/22581/us/adcore-inc" target="_blank"><i></i></a>
        <div class="middle-section">
          <a class="logo-4"><i></i></a>
          <a class="logo-5"><i></i></a>
          <a class="logo-6"><i></i></a>
          <a class="logo-7"><i></i></a>
        </div>
        <div>
          <a class="logo-8"><i></i></a>
          <a class="logo-9"><i></i></a>
          <a class="logo-10"><i></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-view-top" *ngIf="!isDesktopDevice">
    <div class="top">
      <span class="logo"></span>
      <label>{{translate('MEDIA_BLAST')}}</label>
    </div>
  </div>
  <div class="main-box">
    <div class="main-form" [ngClass]="activeIndex === 3 ? 'success-step-align' : ''" style="overflow: scroll;">
      <div class="form-steps" *ngIf="activeIndex !== 3 && !updateInProcess">
        <div class="card">
          <p-button styleClass="back-btn" *ngIf = "activeIndex > 0" (onClick)="goBack()"><img src="/assets/icons/arrow-back-grey.svg"><span>{{translate('BACK')}}</span></p-button>
          <p-steps [model]="stepItems" [readonly]="false" [activeIndex]="activeIndex" styleClass="steps-wiz"
                   (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
        </div>
        <div *ngIf="activeIndex == 0">
          <ng-container>
            <h3> {{translate("APPLY_TO_MEDIA_BLAST")}}</h3>
            <h2>{{translate("YOUR_PERSONAL_DETAILS")}}</h2>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('FIRSTNAME') + '*'}}</div>
              <input id="firstName" name="firstName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('FIRSTNAME') + '*' : ''"
                     [(ngModel)]="formInfo.firstName" autocomplete="off" [required]="true"
                     [disabled]="inProcess"/>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('LASTNAME') + '*'}}</div>
              <input id="lastName" name="lastName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('LASTNAME') + '*' : ''"
                     [(ngModel)]="formInfo.lastName" autocomplete="off" [required]="true" [disabled]="inProcess"/>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">Phone number*</div><!--TODO: add to translation-->
              <aux-phone #phone [countryCode]="currentCountryCode"  [modelPhoneNumber]="formInfo.phoneNumber"
                         (phoneChangeEvent)="onPhoneOnChange($event)" [classStyle]="'purple-darker-palette'"
                         [showExamplePlaceholder]="false" [placeholder]="translate('NG_BC_PHONE_NUMBER')"
                         [required]="true"></aux-phone>
            </div>
            <div class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('EMAIL_PLACEHOLDER') + '*'}}</div>
              <input id="email" name="email" type="text" pInputText [placeholder]="isDesktopDevice ? translate('EMAIL_PLACEHOLDER') + '*' : ''"
                     [(ngModel)]="formInfo.email" autocomplete="off" [required]="true" [disabled]="inProcess" (keyup)="removeError('email')"/>
            </div>
            <div class="field">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('FURTHER_CONTACT_DETAILS')}}</div>
              <p-dropdown styleClass="w-100" [options]="furtherContactTypes" [(ngModel)]="formInfo.contactType" optionLabel="label"
                          optionValue="value" [placeholder]="translate('FURTHER_CONTACT_DETAILS')"></p-dropdown>
            </div>
            <div *ngIf="formInfo.contactType.length" class="field fluid">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate(contactValuePlaceHolder)}}</div>
              <input id="contactValue" name="contactValue" type="text" pInputText [placeholder]="isDesktopDevice ? translate(contactValuePlaceHolder) : ''"
                     [(ngModel)]="formInfo.contactValue" autocomplete="off" [required]="true"
                     [disabled]="inProcess"/>
            </div>
            <div class="field">
              <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COUNTRY') + '*'}}</div>
              <p-dropdown styleClass="w-100 countries" [options]="countries" [(ngModel)]="formInfo.companyCountry" optionLabel="label"
                          optionValue="value" [placeholder]="translate('COUNTRY') + '*'"></p-dropdown>
            </div>
          </ng-container>
          <div class="btn-box">
            <small class="login-error mb-1" id="phone-error" *ngIf="phoneError">{{phoneError}}</small>
            <small class="login-error mb-1" id="email-error" *ngIf="emailError">{{emailError}}</small>
            <p-button styleClass="next-btn" [label]="translate('CONTINUE')" [disabled]="checkDisabled(1)" (onClick)="next()"></p-button>
          </div>
        </div>
        <div class="media" *ngIf="activeIndex == 1">
          <h3>{{translate('YOUR_MEDIA_NEEDS')}}</h3>
          <div>
            <h4>{{translate('DESIRED_CHANNELS')}}*</h4>
            <div *ngFor="let mediaType of mediaTypes" class="field-checkbox">
              <div>
                <p-checkbox name="mediaType" value="mediaType" [value]="mediaType.value"
                            [(ngModel)]="formInfo.selectedMediaTypes" [inputId]="mediaType.value"></p-checkbox>
                <label [for]="mediaType.value">{{translate(mediaType.label)}}</label>
              </div>
            </div>
          </div>
          <div>
            <h4>{{translate('INDUSTRY')}}*</h4>
            <div *ngFor="let industry of industries" class="field-checkbox">
              <p-checkbox name="industry" [value]="industry.value"  [(ngModel)]="formInfo.industry" [inputId]="industry.value"></p-checkbox>
              <label [for]="industry.value">{{translate(industry.label)}}</label>
            </div>
          </div>
          <div class="spend">
            <h4>{{translate('TOTAL_MONTHLY_AD_SPEND')}}*</h4>
            <div>
              <p-dropdown styleClass="w-100" [options]="spends" [(ngModel)]="formInfo.spend" optionLabel="label"
                          optionValue="value" [placeholder]="translate('SELECT_AD_SPEND')"></p-dropdown>
            </div>
          </div>
          <div class="btn-box">
            <p-button styleClass="next-btn" label="Next" [disabled]="checkDisabled(2)" (onClick)="next()"></p-button>
          </div>
        </div>
        <div class="company" *ngIf="activeIndex == 2">
          <ng-container>
            <h3>{{translate('COMPANY_DETAILS')}}</h3>
            <div class="field">
              <h4>{{translate('BUSINESS_TYPE')}}*</h4>
              <p-dropdown styleClass="w-100" [options]="businessTypes" [(ngModel)]="formInfo.businessType" optionLabel="label"
                          optionValue="value" [placeholder]="translate('BUSINESS_TYPE') + '*'"></p-dropdown>
            </div>
            <div *ngIf="formInfo.businessType !== 'Freelancer'">
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COMPANY_NAME') + '*'}}</div>
                <input id="companyName" name="companyName" type="text" pInputText [placeholder]="isDesktopDevice ? translate('COMPANY_NAME') + '*' : ''"
                       [(ngModel)]="formInfo.companyName" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
              </div>
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COMPANY_ADDRESS') + '*'}}</div>
                <input id="companyAddress" name="companyAddress" type="text" pInputText [placeholder]="isDesktopDevice ? translate('COMPANY_ADDRESS') + '*' : ''"
                       [(ngModel)]="formInfo.companyAddress" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
              </div>
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('COMPANY_REG_NO')}}</div>
                <input id="registrationNumber" name="registrationNumber" type="text" pInputText autocomplete="off"
                       [placeholder]="isDesktopDevice ? translate('COMPANY_REG_NO'): ''" [(ngModel)]="formInfo.registrationNumber"
                       [required]="false"  [disabled]="inProcess"/>
              </div>
              <div class="field fluid mb-3">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('WEBSITE_URL') + '*'}}</div>
                <input id="website" name="website" type="text" pInputText [placeholder]="isDesktopDevice ? translate('WEBSITE_URL') + '*': ''"
                       [(ngModel)]="formInfo.website" autocomplete="off" [required]="true" (keyup)="removeError('website')"
                       [disabled]="inProcess"/>
              </div>
            </div>
            <div *ngIf="formInfo.businessType === 'Freelancer'">
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('PERSONAL_ID') + '*'}}</div>
                <input id="personalId" name="personalId" type="text" pInputText [placeholder]="isDesktopDevice ? translate('PERSONAL_ID') + '*' : ''"
                       [(ngModel)]="formInfo.personalId" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
              </div>
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('HOME_ADDRESS') + '*'}}</div>
                <input id="homeAddress" name="homeAddress" type="text" pInputText [placeholder]="isDesktopDevice ? translate('HOME_ADDRESS') + '*' : ''"
                       [(ngModel)]="formInfo.homeAddress" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
              </div>
              <div class="field fluid">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('TAX_ID_NO')}}</div>
                <input id="taxIdNumber" name="taxIdNumber" type="text" pInputText autocomplete="off"
                       [placeholder]="isDesktopDevice ? translate('TAX_ID_NO'): ''" [(ngModel)]="formInfo.taxId"
                       [required]="false"  [disabled]="inProcess"/>
              </div>
              <div class="field fluid mb-3">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('WEBSITE_URL') }}</div>
                <input id="personalWebsite" name="personalWebsite" type="text" pInputText [placeholder]="isDesktopDevice ? translate('WEBSITE_URL') : ''"
                       [(ngModel)]="formInfo.website" autocomplete="off" [required]="true" (keyup)="removeError('website')"
                       [disabled]="inProcess"/>
              </div>
              <div class="field fluid mb-3">
                <div class="input-title" *ngIf="!isDesktopDevice">{{translate('LINKEDIN_FACEBOOK_PROFILE') + '*'}}</div>
                <input id="profile" name="profile" type="text" pInputText [placeholder]="isDesktopDevice ? translate('LINKEDIN_FACEBOOK_PROFILE') + '*' : ''"
                       [(ngModel)]="formInfo.profile" autocomplete="off" [required]="true"
                       [disabled]="inProcess"/>
              </div>
            </div>

          </ng-container>
          <div class="btn-box">
            <small class="login-error mb-1" id="website-error" *ngIf="websiteError">{{websiteError}}</small>
            <small class="login-error mb-1" id="file-error" *ngIf="fileError">{{fileError}}</small>
            <p-button styleClass="next-btn" [label]="translate('SUBMIT')" [disabled]="checkDisabled(3) || inProcess" (onClick)="submit()"></p-button>
          </div>
        </div>
      </div>
      <div class="success-step" *ngIf="activeIndex == 3 && !updateInProcess">
        <div class="top-section">
          <div>
            <div class="success-icon"><i></i></div>
          </div>
          <h3>{{translate('THANK_YOU')}}</h3>
          <div class="submit-text">
            <p *ngIf="submitted">{{translate('SUBMIT_SUCCESS_INFO')}}</p>
            <p *ngIf="!submitted">{{translate('SUBMIT_ERROR_INFO')}}</p>
          </div>
        </div>
      </div>
      <div class="center-container" *ngIf="updateInProcess">
        <p-progressSpinner ></p-progressSpinner>
      </div>
    </div>

    <div class="footer">
      <div class="inline-flex footer-text">
        <a href="https://www.adcore.com/privacy-policy/" style="text-decoration:none" target="_blank">Privacy Policy</a>
        <span class="separator">|</span>
        <a href="https://www.adcore.com/terms-of-use/" style="text-decoration:none" target="_blank">Terms of Use</a>
      </div>
      <div class="translate-drop inline-flex">
        <p-menu styleClass="globe-lang-menu" #menu [popup]="true" [model]="languageMenuItems" appendTo="body" (onShow)="onMenuShow($event)"></p-menu>
        <p-button styleClass="globe-btn" [icon]="!updateInProcess ? 'pi pi-globe' : 'pi pi-spin pi-globe'"
                  [label]="currentLanguage" (click)="menu.toggle($event)"></p-button>
      </div>
    </div>
  </div>
</div>
