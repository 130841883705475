import * as aux from 'adcore-auxiliary';
import * as cfg from './config';

export interface IParams {
  module_fn: string;
  [optional: string]: any;
}

export interface IUser {
  _id?: string;
  id?: string;
  name: string;
  firstName?: string;
  lastName?: string;
  email: string;
  role: string;
  advertiserId: string;
  language?: string | 'en';
  photo: {
    filename: string
  };
  status?: boolean;
  licenseAvailable?: boolean;
  [optional: string]: any;
}

export interface IAdvertiser {
  _id: string;
  type: EAdvertiserType | aux.EMediaSubType;
  companyName: string;
  children?: any[];
  modules?: any;
  signUpPlan?: string;
  [optional: string]: any;
}

export interface ILoginInfo {
  username: string | null;
  password: string | null;
  socialLogin: string | null;
}


export interface IAuthState {
  result?: boolean;
  role?: string;
  err?: string;
  errCode?: string;
  failedAttempts?: number;
  [optional: string]: any;
}

export interface IAppInfo {
  route?: string;
  name?: string;
  label?: string;
}


export interface IOptions {
  module_fn: string;
  advertiser_id?: string;
  [optional: string]: any;
}

export interface IMediaResult {
  rows: aux.IMediaRow[];
  total: number;
  [optional: string]: any;
}

export interface IAdvertiserResult {
  userAdvertiser: aux.IMediaRow | null;
  selectedAdvertiser: aux.IMediaRow | null;
}
export interface IMedia {
  _id: string;
  advertiserId: string;
  status: string;
  name: string;
  type: string;
  data: any;
  channelName: string;
  [optional: string]: any;
}
export interface IPage {
  _id: string;
  name: string;
  columns?: any[];
  order: number;
  hidden?: boolean;
  binEnabled: boolean;
  entitySettings: any;
  modules: string[];
  appName: string;
  [optional: string]: any;
}
export interface IThereMessage {
  key: string;
  mode: string;
  channel?: IMedia | null;
  mcc?: IMedia | null;
  message?: any | null;
  app: string;
  route?: string | null;
  advertiser: IAdvertiser;
  userAdvertiser: IAdvertiser;
  userAdvertiserAndKids: aux.IMediaRow[];
  translations: any;
  token: string;
  user: IUser;
  page: IPage;
  queryParams?: any;
  viewId?: string;
  timePeriod?: IOldTimePeriod | null;
  feeditorWorkMode?: cfg.EFeeditorWorkModes;
  allPages: {[optional: string]: string};
  [optional: string]: any;
}

export interface IOldTimePeriod {
  type: string;
  fromDate?: any;
  toDate?: any;
  from?: any;
  to?: any;
  deltaToday?: number | null;
  deltaYesterday?: number | null;
}

export interface IBackMessage {
  key: string;
  mode: string;
  message?: any | null;
  [optional: string]: any;
}

export enum EAdvertiserType {
  NONE = '',
  SYSTEM = 'system',
  AGENCY = 'agency',
  CLIENT = 'client'
}

export interface ISideMenuEvent {
  menuClicked: aux.IMediaRow | null;
  originEvent?: MouseEvent;
  show: boolean;
}

export enum EToolBarMode {
  NONE = -1,
  ACTIONS = 0,
  ATTRIBUTES = 1
}

export interface IAction {
  componentKey: string;
  key: string;
  data?: any;
  icon?: string;
  visible?: boolean;
  validate?: () => void;
  [optional: string]: any;
}
// dynamic_props = ["adDynamicViewId", "keywordDynamicViewId", "campaignDynamicViewId"]
export interface IDialogResult {
  dialogAction?: string;
  mode?: aux.EMediaMode;
  advertiserId?: string;
  advertiserRow?: aux.IMediaRow | null;
  feedId?: string;
  mediaRow?: aux.IMediaRow | null;
  accountId?: string;
  versionId?: string;
  versionRow?: aux.IMediaRow | null;
  dynamic?: any;
  [optional: string]: any;
}

export interface  ICommandResult {
  command: string;
  mode: string;
  completed: boolean;
  result: any;
  [optional: string]: any;
}

export interface IDynamicInfo {
  adDynamicViewIdRow: aux.IMediaRow;
  keywordDynamicViewIdRow: aux.IMediaRow;
  campaignDynamicViewIdRow: aux.IMediaRow;
}

export enum ESubscriptionType {
  INITIAL = '',
  PAID = 'paid',
  TRIAL = 'trial',
  FREE = 'free',
  ENTERPRISE = 'enterprise',
  CANCELED = 'canceled',
  EXPIRED = 'expired'
}

export interface IPlanSettings {
  plan: string;
  paymentFrequency: string;
  feedSize?: string;
  totalLicenses: number;
  tasks: number;
  availableLicenses: number;
  tasksAddon: number;
  isLicenseOwner: string | 'no';
  promoCode?: string;
  freeTasks?: number;
  licenseOwnerStartDate?: number | null;
  subscriptionType?: ESubscriptionType;
  subscriptionStart?: number | null;
  subscriptionEnd?: number | null;
  subscriptionEnded?: boolean;
  subscriptionCanceled?: any;
  billingContact?: IBillingContact;
  billingCustomerId?: string;
  billingPaymentId?: string;
  billingTestClockId?: string; // only available when using test clock in development
  [optional: string]: any;
}


export interface IMediaBlastPaymentProfile {
  billingContact?: IBillingContact;
  billingCustomerId?: string;
  availablePlans?: string[];
  agreedWithTerms?: any;
  maxAccountsPerChannel?: number;
  maxFailuresPerChannel?: number;
  maxAdSpend?: number;
  addOnFeePercent?: number;
  price?: number;
  channels?: string[];
  subscriptionCanceled?: number;
  subscriptionEnd?: number;
  subscriptionPlan?: string;
  subscriptionStart?: number;
  subscriptionStatus?: string;
  subscriptionId?: string;
  topUpPaymentMethod?: string;
  creditCardApproval?: ICreditCardApproval;
  [optional: string]: any;
}

export interface ICreditCardApproval {
  email: string;
  approved: boolean;
  date: Date;
}

export interface ICreditCardApproval {
  email: string;
  approved: boolean;
  date: Date;
}

export interface IUsage {
  stats: any;
  calculatedTotalTasks: number;
  selectedTotalTasks: number;
  licenseOwner: aux.IMediaRow;
  usedTaskCalculatedPercent: number;
  isTrial: boolean;
  [optional: string]: any;
}

export interface IUsageStats {
  calculatedTotalTasks: number;
  effortlessPoints: number;
  [optional: string]: any;
}

export enum EItemType {
  ARTICLE = 'article',
  VIDEO = 'video',
  CUSTOM = 'custom',
  ALERTER_POLICY_GUIDE = 'alerter_policy_guide',
  MEDIA_BLAST_POLICY_GUIDE = 'media_blast_policy_guide',
  MEDIA_BLAST_ARTICLES = 'media_blast_articles',
  CHECKLIST_POLICY_GUIDE = 'checklist_policy_guide',
  VIEW_COCKPIT = 'view_cockpit',
}

export enum ECreditCardState {
  NONE = '',
  EXIST = 'yes',
  NOT_EXIST = 'no',
  EXPIRED = 'expired',
}

export interface IMyCloudListItem {
  currentUserId: number;
  status?: string;
  itemType: string;
  contentLink: string;
  contentType: string;
  previewLink: string;
  previewType: string;
  titleTranslationKey: string;
  titleOriginalEnglish: string;
  descTranslationKey?: string;
  descOriginalEnglish?: string;
  publishDate: string;
  category?: string;
  duration?: string;
  itemOrder?: number;
  createdOn?: Date;
  channels?: string[];
}

export interface ISemdocGeneralSettings {
  bidGoalTarget: string;
  [optional: string]: any;
}

export interface IVersionPanelItem {
  title: string;
  auxInfoContent: string;
  bodyContent: string;
  footer?: {
    content: string,
    enabled: boolean
  };
  linkButton?: {
    enabled: boolean,
    label: string,
    onClick: any
  };
  images?: string[];
  class?: string;
}

export interface IPricingConfig {
  MAX_ADDON_TASKS: number;
  MIN_ADDON_TASKS: number;
  ADDON_STEP_TASKS: number;
  ALL_APPS_TASKS_PER_LICENSE: number;
  SINGLE_APP_TASKS_PER_LICENSE: number;
  MONTHLY_MAX_ADDON_PRICE: number;
  MONTHLY_MIN_ADDON_PRICE: number;
  YEARLY_MAX_ADDON_PRICE: number;
  YEARLY_MIN_ADDON_PRICE: number;
  YEARLY_PRICE_RANGE: number[];
  MONTHLY_PRICE_RANGE: number[];
  MONTHLY_PRICE_ALL_APPS: number;
  YEARLY_PRICE_ALL_APPS: number;
  MONTHLY_PRICE_SINGLE_APP: number;
  YEARLY_PRICE_SINGLE_APP: number;
  [optional: string]: any;
}

export interface IPricingPlan {
  totalBaseAmount: number;
  addonPricePerMonth: number;
  licensePricePerMonth: number;
  licensePrice: number;
  priceForAddonTasks: number;
  possibleEndDate: number;
  nextInvoice?: any;
  currentSubscription?: any;
  scheduledSubscription?: any;
  usersCount?: number;
  addonTasks: number;
  discountAmount?: number;
  [optional: string]: any;
}

export interface IBillingContact {
  id?: string;
  name: string;
  email: string;
  alternateEmail?: string;
  phone: string;
  addressCity: string;
  addressCountry: string;
  addressLine: string;
  addressPostalCode: string;
  organization?: string;
  [optional: string]: any;
}

export interface ICreditCardInfo {
  id?: string;
  cardNumber: string;
  cardHolder: string;
  cardExpiration: string;
  cardCVV?: string;
  cardBrand?: string;
  [optional: string]: any;
}

export interface IForecastElement {
  scaleMin: number;
  scaleMax: number;
  value: number;
  position: number;
  isLast?: boolean;
}

export interface ILateConversionsItem {
  label: string;
  image: string;
  value?: number;
  percent?: number;
}


export interface IFeedPlusStatsSummary {
  totalFeeds: number;
  totalItems: number;
  totalVersions: number;
  total12hFeeds: number;
  total24hFeeds: number;
}

export interface IFeedPlusStatsRow {
  feedId: string;
  feedName: string;
  feedStatus: string;
  maxVersionsCount: number;
  maxRowsCount: number;
  monthlyUses: number;
}

export interface ICockpitItem {
  title: string;
  hidden: boolean;
  tipDialog?: string;
}

export interface IMBPlanInfo {
  price: number;
  maxAccountsPerChannel: number;
  maxFailuresPerChannel: number;
  maxAdSpend: number;
  addOnFeePercent: number;
  mostPopular: boolean;
  channels: string[];
}

export interface IBankTransfer {
  _id?: string;
  referenceNumber: string;
  status: string;
  amount: number;
  currency: string;
  referenceFileId?: string;
  referenceFileName?: string;
  referenceFileType?: string;
  sentOn?: number;
  sentBy?: string;
  advertiserId?: string | null;
  advertiserName?: string;
  approvedBy?: any;
  [optional: string]: any;
}

export interface IBankTransferEvent {
  bankTransfer?: IBankTransfer;
  originEvent?: any;
  referenceFile?: File | null;
}
